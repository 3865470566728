<template>
  <div class="mobile" @click="otherClose" v-loading="loading" >
     <!-- <img src="@/assets/images/123.png" alt="" class="top-bg-img" /> -->
     <van-field left-icon="search" v-model="searchValue" @keypress="searchGoods"  placeholder="搜索" />
     <div style="display:flex;flex-wrap:wrap;width:100%;margin-top:20px;">
        <div class="model-item" v-for="(item, index) in modelData" :key="index">
          <!-- <div class="bg-content"> -->
            <div class="top-bg">
              <div v-if="item.is_system == 1" class="flag fz12 examples" style="background: #fff; color: black">
                示例
              </div>
              <div v-if="item.is_system == 2" class="flag fz12 model-flag" style="background: #fccf53">
                免费
              </div>
              <div v-else-if="item.is_system == 0 && item.temp_status === 1" class="flag fz12 top-flag">
                已启用  
              </div>
              <div v-else-if="item.is_system == 0 && item.temp_status === 2" class="flag fz12 no-flag">
                未启用
              </div>
              <div v-else-if="item.is_system == 0 && item.temp_status === 3" class="flag fz12 del-flag">
                已删除
              </div>
            </div>
            <div class="bg-content">
              <div v-if="item.is_system > 0">
              <!-- {{item.modular}} -->
                <img v-for="(app, i) in item.modular" :key="i" :src="app.show_image" alt="" class="model-item-img" />
              </div>
              <div v-else>
                <img v-for="(app, i) in item.modular" :key="i" :src="getAppBg(app.modular_type, item.is_system)" alt="" class="model-item-img" />
              </div>
            </div>
            <div class="bottomFrame">
              <span style="font-size:14px;width:70%;">{{temp_nameHandle(item.temp_name)}}</span>
              <img class="icon" src="@/assets/images/ellipsis.svg" alt="" @click="operationEvent(item,index)"  v-if="item.is_system !== 1" >
              <div class="operationBox" v-if="showIndex == index">
                  <div class="div1"  v-show="item.temp_status === 2" @click="handClickOpen(item)">启用</div>
                  <div class="div1" style="margin-bottom:0px;" v-show="item.temp_status === 1" :disabled="item.temp_status !== 1" @click="handClickClose(item)">关闭</div>
                  <div class="div1"  v-show="item.temp_status === 3" @click="handClickRecovery(item)">恢复</div>
                  <div class="div2"  v-show="item.temp_status === 0 || item.temp_status === 2" @click="handClickDelete(item,1)">删除</div>
                  <div class="div2"  v-show="item.temp_status === 3" @click="handClickDelete(item,2)">彻底删除</div>
              </div>
            </div>
    </div>
     </div>
      
  </div>
</template>

<script>
  import {
    indexGetList,
    closeModel,
    delModel,
    useModel,
    deletes,
    recoveryModel,
    getModelList
  } from '@/api/workbench'
  import VueHoverMask from 'vue-hover-mask'
  import { Dialog } from 'vant';
  export default {
    name: "index",
    components: {
      VueHoverMask
    },
    computed: {
      getAppBg() {
        return function (type, system) {
          if (system == 1) {
            if (type == 'keydata') {
              return require('@/assets/images/keydata.png')
            } else if (type == 'image') {
              return require('@/assets/images/img.png')
            } else if (type == 'list') {
              return require('@/assets/images/list.png')
            } else {
              return require('@/assets/images/webview.png')
            }
          } else {
            if (type == 'keydata') {
              return require('@/assets/images/model1.png')
            } else if (type == 'image') {
              return require('@/assets/images/model2.png')
            } else if (type == 'list') {
              return require('@/assets/images/model3.png')
            } else {
              return require('@/assets/images/model4.png')
            }
          }
        }
      },
      use_template() {
        return this.$store.state.listData.use_template
      },
      del_template() {
        return this.$store.state.listData.del_template
      },
    },
    created() {
      this.delToken()
      // location.href = "http://workbench.temp.dev.mgtx.com.cn/front/dist/index.html#/Staffing?s=%7B%22type%22%3A2%2C%22form_appid%22%3A16742%2C%22corpid%22%3A%22ww1a89fffea6a902dd%22%2C%22agent_id%22%3A1000005%2C%22service_id%22%3A10%7D"
    },
    mounted() {
      this.getModelList()
    },
    data() {
      return {
        switchDisplay: false,
        modelData: [],
        showIndex:-1,
        loading:false,
        searchValue:'',
      };
    },
    methods: {
       delToken() {
          var reg = new RegExp("(^|&)" + "token" + "=([^&]*)(&|$)");
          if (window.location.search.substr(1).match(reg)) {
            //去掉地址栏token
            history.replaceState(
                null,
                null,
                location.origin + location.pathname + location.hash
            );
          }
        },
      searchGoods(event){
        if (event.keyCode == 13) {
          event.preventDefault();
          this.getModelList()
        }
        
      },
      getModelList() {
        getModelList({page:1,page_size:100,temp_name:this.searchValue}).then((res) => {
          if (res.code == 200) {
            this.modelData = res.data.data
            // .slice(0, 3)
          } else {
            this.$message.error('未获取到数据，请稍后再试')
          }
        }).catch((err) => {
          console.log(err)
        })
      },
      //点击操作事件
      operationEvent(item,index){
        this.showIndex = index
      },
      //开启事件
      handClickOpen(item){
       
        let that = this
        that.showIndex = -1
        Dialog.confirm({
          title: '确定启用并推送给员工吗',
          confirmButtonText:'启用并推送',
          confirmButtonColor:'#409eff',
          message:'推送成功后，员工的企业微信-工作台页面会自动刷新，数据推送可能会有延时',
        }).then(() => {
          this.loading = true
          useModel({ id:item.id}).then((res) => {
          if (res.code === 200) {
              that.getModelList()
              that.$message({
                message: res.message,
                type: 'success'
              });
          } else {
              that.$message({
                message: '操作失败，请重试',
                type: 'warning'
              });
          }
          this.loading = false
        })
        .catch((err) => {})
        }).catch(() => {
            // on cancel
        });
      },
      //关闭
      otherClose(e){
        if(e.target.className != 'icon'){
          this.showIndex = -1
        }
      },
      //关闭按钮
      handClickClose(item){
         let that = this
        this.loading = true
         closeModel({ id:item.id }).then((res) => {
          if (res.code === 200) {
            that.$message({
              message: res.message,
              type: 'success'
            });
            that.getModelList()
            that.showIndex = -1
          } else {
            that.$message({
              message: '操作失败，请重试',
              type: 'warning'
            });
          }
           this.loading = false
        })
      },
      //名字过长处理事件
      temp_nameHandle(name){
        if(name.length <= 13){
          return name
        }else{
         return name.substring(0,10)+'...'
        }
        
        console.log(name)
      },
      //删除按钮
      handClickDelete(item,type){
        let that = this
        that.showIndex = -1
        if(type == 1){
           Dialog.confirm({ title: '是否确定删除', }).then(() => {
            this.loading = true
            delModel({ id:item.id }).then((res) => {
              if (res.code === 200) {
                that.$message({
                  message: res.message,
                  type: 'success'
                });
                that.getModelList()
              } else {
                that.$message({
                  message: '操作失败，请重试',
                  type: 'warning'
                });
              }
               this.loading = false
            })
          })
          .catch(() => {
              // on cancel
          });
        }else{
          Dialog.confirm({ title: '是否确定彻底删除', }).then(() => {
            this.loading = true
            deletes({ id:item.id }).then((res) => {
              if (res.code === 200) {
                that.$message({
                  message: res.message,
                  type: 'success'
                });
                that.getModelList()
              } else {
                that.$message({
                  message: '操作失败，请重试',
                  type: 'warning'
                });
              }
              this.loading = false
            })
          })
          .catch(() => {
              // on cancel
          });
        }
       
      },
      //恢复
      handClickRecovery(item){
        this.loading = true
         recoveryModel({ id: item.id }).then((res) => {
          if (res.code === 200) {
            this.$message({ type: 'success',   message: res.message})
            this.getModelList()
          } else {
            this.$message.error('操作失败，请重试')
          }
        })
        .catch((err) => {
          console.log(err)
        })
        this.loading = false
      }
    },
  };
</script>

<style lang="less">
  .flex {
    display: grid;
    // display: -webkit-flex;
    // justify-content: space-between;
    // -webkit-justify-content: space-between;
    // align-items: center;
    // -webkit-align-items: center;
  }
  .top-bg-img {
        // height:50px;
        width: 100%;
      }
  .model-item{
    // position: relative;
    width:45%;
    margin-bottom:20px;
    margin-left:3%;
    .bottomFrame{
      // position:absolute;
      // bottom:0;
      // left:0;
      border-top-right-radius:4px;
      border-top-left-radius:4px;
      margin-top:-20px;
      height:40px;
      padding:0 15px;
      display:flex;
      align-items:center;
      justify-content:space-between;
      background:#FFF;
      width:100%;
      font-weight:700;
      z-index:99;
      position:relative;
      box-shadow: 0px 2px 12px #E6EBF5;
      .icon{
        width:15px;
      }
      .operationBox{
        position:absolute;
        right:40px;
        background:#545965;
        padding:10px 20px;
        border-radius:10px;
        div{
          color:#FFF;
        }
        .div1{
          margin-bottom:10px;
          border-bottom:1px solid #4e535F;
          font-size: 15px;
        }
        .div2{
          margin-top:10px;
          border-top:1px solid #4e535F;
          font-size: 15px;
        }
      }
    }
    .model-item-img {
      width: 100%;
      padding: 3px 5px;
    }
  }
  .bg-content {
      width: 100%;
      height: 250px;
      overflow: hidden;
      background: #f6f6f6;
   }
  .mobile {
    width: 100%;
    height: 100%;
    background: #F1EFF2;
    // display: flex;
    // flex-direction: row-reverse;
    overflow-y:scroll;
    .mobile-item {
      
    }
  }
   .top-bg {
        // height: 273px;
        width: 100%;
        position: relative;
        .bg-content {
          width: 100%;
          height: 273px;
          overflow: hidden;
          background: #f6f6f6;
          .model-item-img {
            width: 100%;
            margin: 3px 5px;
          }
        }
        .flag {
          width: 56px;
          height: 24px;
          border-radius: 2px 0 16px 0;
          color: #ffffff;
          position: absolute;
          top: 4px;
          left: 5px;
          text-align: center;
          line-height: 24px;
        }
        .top-flag {
          background: #67c23a;
        }
        .model-flag {
          background: #fccf53;
        }
        .no-flag {
          background: #cfcfcf;
        }
        .del-flag {
          background: #ff3a3a;
        }
      }
</style>